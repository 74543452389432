<template>
  <div class="relatorio">
    <v-progress-circular class="ralatorio-load" color="primary" :width="4" :size="70" indeterminate />
    <v-container fluid>
      <iframe class="iframe" frameborder="0" height="90%" width="100%" :src="url"></iframe>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

// mixins para com funcao padroes na geração de relatorios
// import mixinRelatorio from '@/views/relatorios/mixins/relatorio'
// eslint-disable-next-line
import { map, size, last, each, uniqBy } from 'lodash'
export default {
  name: 'RelatorioBI',
  data: () => ({
    request: '',
  }),
  beforeDestroy() {
    clearInterval(this.request)
    clearInterval(this.selector)
  },
  computed: {
    ...mapState('relatorioDreRede', { dados: 'conteudo' }),
    ...mapGetters('auth', ['user']),
    parentPath () {
      const path = this.user.userEntityPaths
      const types = {
        supervisor: [],
        promotor: [],
        revendas: []
      }
      if (!path[0]) return `admin`

      each(this.user.userEntityPaths, v => {
        const tamanho = String(v).split('.')
        const final = last(tamanho)
        if (size(tamanho) === 2) return types.supervisor.push(`${final}`)
        if (size(tamanho) === 3) return types.promotor.push(`${final}`)
        return types.revendas.push(`${final}`)
      })
      Object.keys(types).forEach(v => { types[v] = uniqBy(types[v]) })

      return types
    },
    url () {
      // return encodeURI(`https://analytics.zoho.com/open-view/2584235000000077601?ZOHO_CRITERIA="vw_entities_bi"."parent_path" in (${this.parentPath})`)
      // return `https://analytics.zoho.com/open-view/2584235000000077601?ZOHO_CRITERIA="vw_entities_bi"."parent_path"%3D'1'`
      let url = `https://analytics.zoho.com/open-view/2584235000000077601`
      const params =  `?ZOHO_CRITERIA="vw_full_entities_bi".`
      const dados = this.parentPath
      if (dados === 'admin') return url

      const sizeSuper = size(dados.supervisor)
      const sizePromotor = size(dados.promotor)
      const sizeRevendas = size(dados.revendas)
      
      if (sizeSuper) url += `${params}"supervisor_id" in (${dados.supervisor || null})`
      
      if (sizePromotor) {
        url += `${ sizeSuper ? 'or ' : params}"promotor_id" in (${dados.promotor.join(',') })`
      }
      if (sizeRevendas) {
        url += `${(sizeSuper || sizePromotor) ? 'or ': params}"id" in (${dados.revendas.join(',')})`
      }

      return url
      // return `https://analytics.zoho.com/open-view/2584235000000077601?ZOHO_CRITERIA="vw_entities_bi"."parent_path" in ( '1.83097.83192.83233','1.83097.83192.83212')`
    },
  },
  methods: {
    ...mapActions('relatorioDreRede', ['getHtml', 'requestRelatorio'])
  }
}
</script>

<style lang="scss" scoped >
$cor: #0091ff;
  .relatorio {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    width: 100%;
    display: flex;
    align-items: 'center';
    justify-content: 'center';
    .iframe {
      margin-top: 20px;
      min-height: calc(100vh - 112px + 24px);
      border: none;
      margin: -24px;
      width: calc(100% + 50px);
    }
  }
  .v-progress-circular{
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: -1 !important;
  }
</style>